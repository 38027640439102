/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 12, 2023 */



@font-face {
    font-family: 'jha_myhappy70sregular_italic';
    src: url('myhappy70s-rgit-webfont.woff2') format('woff2'),
         url('myhappy70s-rgit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'jha_myhappy70sregular';
    src: url('myhappy70s-rg-webfont.woff2') format('woff2'),
         url('myhappy70s-rg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'jha_myhappy70ssemibold_italic';
    src: url('myhappy70s-smbdit-webfont.woff2') format('woff2'),
         url('myhappy70s-smbdit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'jha_myhappy70ssemibold';
    src: url('myhappy70s-smbd-webfont.woff2') format('woff2'),
         url('myhappy70s-smbd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}