$color1: #020D46;
$color3: #DDB079;
$white: #FFF;

#spa-container {
    text-align: center;
}

#planity {
    > div {
        max-width: 790px;
        margin: 0 auto;
    }

    a {
        text-decoration: none;
    }

    nav {
        padding: 0 10px;
    }

    nav a, .planity_ui_action_button_root {
        border:1px solid $color3;
        text-decoration: none;
        text-transform: uppercase;
        color: $color3;
        font-size: 12px;
        line-height: 15px;
        padding: 8px 10px;
        display: inline-block;
        letter-spacing: 2.85px;
        text-align: center;
        &:hover, &:focus {
            background: $color3;
            color: $white;
        }
    }

    .planity_ui_item-list-element {
        h3 {
            padding: 0 10px;
            font-weight: 400;
        }
        >span {
            padding: 0 10px;
            display: block;
            border-radius: 0;
            /*text-transform: uppercase;*/
        }
    }

    button {
        // font-family: $font2;
        border-color: $color1;
        background-color: $color1;
        border-radius: 0;
        text-transform: uppercase;
        color: #FFF;
        letter-spacing: 2.85px;
        font-size: 12px;
    }

    .planity_bookappointment-button-choose {
        color: #FFF;
        letter-spacing: 2.85px;
        font-size: 12px;
        border-color: $color1;
        background: $color1;
        padding: 2px 6px 4px;
        border-radius: 0;

        &:hover,&:focus {
            color: $white;
            background: $color3;
            border-color: $color3;
        }
    }

    .css-umakil {
        width: auto;
    }

    .css-pejxyu {
        align-items: center;
    }

    .planity_ui_appointment_background {
        > button {
            border-color: $color1;
            color: $color1;
            background: transparent;
            max-height: none;
            text-align: left;

            svg {
                circle {
                    fill: $color1;
                }
            }

            @media (min-width: 576px) {
                width: auto;
            }

            &:hover, &:focus {
                border-color: $color3;
                color: $white;
                background: $color3;

                svg {
                    circle {
                        fill: $white;
                    }
                    path {
                        fill: $color3;
                    }
                }
            }
        }
    }

    .planity_appointment_days_slider_hour_availability:hover {
        &:hover {
            color: $color1;
        }
    }

    .css-1fesdig {
        margin: 0 auto;
        @media (min-width: 576px) {
            margin: 0 auto;
        }
    }

    .css-8i1u4h {
        font-family: "brandon-grotesque", sans-serif;
        font-weight: 400;
    }
}

#planitywl {
    >* {
        min-height: 0;
    }
}