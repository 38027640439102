/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 12, 2023 */



@font-face {
    font-family: 'jha_myhappy70sSLtIt';
    src: url('jha_-_my_happy_70s_semilight_italic-webfont.woff2') format('woff2'),
         url('jha_-_my_happy_70s_semilight_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'jha_myhappy70ssemilight';
    src: url('jha_-_my_happy_70s_semilight-webfont.woff2') format('woff2'),
         url('jha_-_my_happy_70s_semilight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}