
:root{
    --slideshow-indicator-width: 90px;
    --slideshow-indicator-inactive-width: 32px;
}

@media all and (max-width: 450px) {  
    :root {  
        --slideshow-indicator-width: 60px;
        --slideshow-indicator-inactive-width: 22px;
    }
}

.slideshow-indicator {
    width: var(--slideshow-indicator-inactive-width);
    transition: width 0.3s ease;
}

.slideshow-indicator.active {
    width: var(--slideshow-indicator-width);
}

.slideshow-indicator .progress-bar {
    opacity: 0;
}

.slideshow-indicator.active .progress-bar {
    opacity: 1;
    animation: progress var(--slideshow-duration) linear;
    transition: width 0.1s linear;
}

@keyframes progress {
    from {width: 5px;}
    to {width: var(--slideshow-indicator-width);}
  }