:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  --display-page-scroll-shift: 0px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* disable rotation */
.landscape-warning {
  display: none;
}
@media screen and (max-height: 400px) and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
  .landscape-warning {
    display: block;
  }
}

@keyframes rotateReveal {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(0);
  }
}

html,
body {
  max-width: 100vw;
}

html {
  viewport-fit: cover;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  height: calc(var(--vh, 1vh) * 100);
}

body {
  overflow-x: hidden;
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

.page-content a {
  text-decoration: underline;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/* lottie fix */
.lottie svg {
  transform: unset !important;
}

img {
  transition: 2s;
}

/* Style for ImageSmooth component */
.img-blur {
  opacity: 1;
  filter: blur(10px);
  transform: scale(1.1);
}

.unblur {
  animation: unblur 0.5s forwards;
}

@keyframes unblur {
  from {
    opacity: 1;
    filter: blur(10px);
    transform: scale(1.1);
  }
  to {
    opacity: 0;
    filter: blur(0);
    transform: scale(1);
  }
}

.unblur-keep {
  animation: unblur-keep 0.3s forwards;
  transform: scale(1.1);
}

@keyframes unblur-keep {
  from {
    opacity: 1;
    filter: blur(10px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
}

.EZDrawer .EZDrawer__container {
  box-shadow: 0 0 100px 30px rgb(0 0 0 / 10%) !important;
}

.hideScrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.hideScrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.chakra-checkbox__control {
  background-color: white;
  border: none;
  border-color: white !important;
  border-width: 1px !important;
  padding: 10px;
}

.chakra-checkbox__control[data-invalid] {
  border-color: red !important;
}

/* Planity */
.planity-component-button {
  font-family: 'SamsungSharpSans-Bold', sans-serif !important;
}

.test {
  border: 2px solid red !important;
  outline: 2px solid red !important;
}

/* HSC */

.kustom-page-rooms #diadao-hsw-hsp-html,
.kustom-page-room #diadao-hsw-hsp-html,
.kustom-page-rooms .hi-widget-container,
.kustom-page-room .hi-widget-container {
  display: none !important;
}

/*
h1 {
  outline: 5px solid red !important;
}
h2 {
  outline: 5px solid orange !important;
}
h3 {
  outline: 5px solid blue !important;
}
h4 {
  outline: 5px solid cyan !important;
}
p {
  outline: 5px solid green !important;
}
*/

/* 
span {
  outline: 5px solid gray !important;
} */
