/* Sortable */
.sortable-ghost {
  opacity: 0.5;
}

.sortable::after {
  content: '';
  flex: 99999 1 auto;
}

.kustomAdminView #chakra-toast-manager-bottom-right {
  bottom: 58px !important;
}

// ui/Input component, on autofill adapt label
.UIInput-Input {
  &:is(&:autofill, &:-webkit-autofill) + .UIInput-Label {
    transform: translate(12px, -9px) scale(0.7);

    &::before {
      content: '-';
      color: white;
      display: block;
      background: '#F6F7FB';
      z-index: -1;
      position: absolute;
      height: 13px;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
}
